@media all and (min-width: 480px) {
    .UpdateUser {
        padding: 10px 0;
    }
    .UpdateUser form {
        margin: 0 auto;
        max-width: 320px;
    }
}

.UpdateUserComponent {
    /* padding: 80px 0; */
    text-align: center;
}

.UpdateUserComponent h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #114c92;
    font-size: 30px;
    padding-top: 0px !important;
}

.UpdateUserComponent p {
    color: rgb(51, 51, 51);
    /* margin: 30px; */
}

.UpdateUserComponentForm {
    padding-top: 50px !important;
}

select.form-control, textarea.form-control, input.form-control {
    height: 46px;
}