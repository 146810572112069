tr:hover {
    cursor: pointer;
}

th:hover {
    cursor: default;
}

h1 {
    font-size: 30px;
}

h1 {
    padding-top: 10px;
}

.Home {
    padding: 10px 0;
    padding-bottom: 50px !important;
    margin-bottom: 50px;
}

.fleet {
    border-style: solid;
    padding: 40px;
    padding-top: 10px;
    padding-bottom: 40px;
    margin-top: 100px;
    border-color: lightgray;
}

.supportButtonHome {
    width: 100%;
    margin: 5px;
}

.listing {
    display: block;
    position: relative;
    width: 100%;
    margin:0px;
    width:100%;
    overflow:hidden;
    overflow-y:scroll;
    max-height: 90px;
    scroll-snap-align: start;
    float: left;
    box-sizing: inherit;
}

.listingLink {
    margin-bottom: 5px;
    display: block;
    position: relative;
    width: 100%;
    float: left;
    box-sizing: inherit;
}

.DeviceCards{
    height: 500 px;
}
