@media all and (min-width: 480px) {
    .Login {
        padding: 10px 0;
    }
    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }
}

h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #114c92;
    padding: 40px 0;
    text-align: center !important;
    font-size: 32px;
    margin-top: 10px;
}

p {
    color: rgb(51, 51, 51);
    margin-bottom: 30px;
    text-align: center;
}