.otpResponse{
    background: #90aca9;
    color: #fff;
    border-radius: 10 !important;
    border-width: 2px !important;
  }

.inputOTP {
    background-color: #d9dfe0;
    border-radius: 4px;
    cursor: unset;
    position: center !important;
  }

.tableFixHead {
    overflow-y: auto !important;
    height: 350px !important;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
    background: white;
    border: 1px solid #dee2e6;
}

.form {
    width: 100%;
}

.RemoteAccessHeader {
  margin-top: 0px;
}

