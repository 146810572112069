p {
    margin-bottom: 0em;
}

.inTableLabel {
    color: #114c92;
}

.responsiveTableCustomersTable {
    height: 600px !important;
    display: block;
    width: 100%;
    /* overflow-x: auto; */
    overflow-y: auto !important;
    border-collapse: collapse;
    position: relative;
}
