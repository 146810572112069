.tableFixHeadRemoteCommand {
    overflow-y: auto !important;
    max-height: 700px !important;
}

.tableFixHeadRemoteCommand thead {
    position: sticky !important;
    border: 1px solid #dee2e6 !important;
}

.tableFixHeadRemoteCommand thead tr th {
    background-color: rgb(235, 236, 236);
}

.scrolledList {
    overflow-y: auto;
    height: 400px;
}

.listItem {
    background-color: rgb(222, 226, 226);
}

.list-group-item {
    position:static;
}

.loadMoreJobsButton {
    display:flex;
    justify-content:center;
    align-items: center;
}

.table-fixed tbody {
    overflow-y: auto;
}

.DetailsButton {
    width: 100%;
    background-color: #dee2e6;
    color: black;
}

.SupportButton {
    width: 50%;
    background-color: #dee2e6;
    color: black;
    display: inline;
}

.cardLicenseManager {
    background-color: rgb(233, 245, 233);
    margin-bottom: 40px;
}

.card-title {
    font-size: 30px !important;
}

.cardLicenseManagerSubscriptions {
    background-color: aliceblue;
}

.LicenseManagerModel {
    border: 1px solid #e5e5e5 !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !important;
    background-color: rgba(0, 0, 0, 0.5);
}

.RenderDetails {
    margin-top: 20px;
    margin-bottom: 20px;
}

.FillFormStyle {
    color: rgb(173, 3, 3);

}