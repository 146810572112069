.button-default {
    color: #eee;
    /* background-color: #114c92;
    border-color: #adadad; */
}

.button-default:hover, .button-default:focus {
    color: #fff;
    /* background-color: #114c92;
    border-color: #adadad; */
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #eee;
    /* background-color: #114c92;
    border-color: #adadad; */
    cursor: not-allowed;
}

.LoginButton {
    font-size: 16px;
	height: 40px;
}
