.ListGroup.ItemDeviceHealthSystems {
    padding: 100px;
}

.listGroupHeading {
    text-align: left;
    background-color: #114c92;
    border-color: #ddd;
    color: #eee;
}

.table-responsive {
    min-height: 100px !important; 
    border-collapse: collapse; 
    position: relative;
}

table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
  }

p {
    text-align: left;
}

.listGroupHeading list-group-item {
    color: #eee;
}

.list-group {
    font-size: 16px;
}

.innerListGroupCard {
    text-align: left;
}

.innerListGroupSubCard {
    text-align: left;
    background-color:  rgb(0, 170, 170) !important;
}

.sdCardCard {
    margin-top: -21px;
}

.MarkdownUserRemark{
    border: '2px solid #fff' !important;
    padding: 5 !important;
}
