@media all and (min-width: 480px) {
    .ActivateTOTP {
        padding: 10px 0;
        display: flex;
    }
    .ActivateTOTP form {
        margin: 0 auto;
        max-width: 550px;
    }
}

.ActivateTOTP form span.help-block {
    font-size: 16px;
    padding-bottom: 10px;
    color: #999;
}


.description {
    padding: 80px 0;
    text-align: center;
}

.description h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #114c92;
    font-size: 32px;
}

.description p {
    color: rgb(51, 51, 51);
    margin: 30px;
}

.pwAlert{
    text-align: left;
}

.Skip {
    text-align: justify; /* For Edge */
    -moz-text-align-last: right; /* For Firefox prior 58.0 */
    text-align-last: right;
}

.ActivatedTOTPHomeButton {
    width: 100%;
}

.ActivatedTOTPForm {
    margin-top: 100px !important;
}