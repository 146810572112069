@media all and (min-width: 480px) {
    .CreateCustomer form {
        margin: 0 auto;
        text-align: left;
    }
}

.CreateCustomer  {
    padding: 10px 0;
}

h3 {
    padding: 24px;
    color: #114c92;
}

.subHeadline {
    color: #114c92;
    padding-top: 12px;
    padding-bottom: 12px;
}

.description {
    padding-top: 0px;
}

.description h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #114c92;
    font-size: 32px;
}

.description p {
    color: rgb(51, 51, 51);
    margin: 0px;
}

select.form-control, textarea.form-control, input.form-control {
    height: 46px;
}

._3vt7_Mh4hRCFbp__dFqBCI li:hover {
    background: #114c92;
}

._3vt7_Mh4hRCFbp__dFqBCI input {
    padding-left: 8px;
    margin-top: 6px;
}

.ErrorMail {
    background-color: #dc3545;;
}

.FeatureMail {
    background-color: #28a745;
}

.InfoMail {
    background-color: #17a2b8;;
}

.WarnMail {
    background-color: #ffc107;;
}