@media all and (min-width: 480px) {
    .CreateUser {
        padding: 10px 0;
    }

    .CreateUser form {
        margin: 0 auto;
        max-width: 320px;
        margin-top: 40px;
    }
}

.CreateUser {
    padding: 10px 0;
}

.description {
    padding: 80px 0;
    text-align: center;
    margin-bottom: 60px !important;
}

.description h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #114c92;
    font-size: 32px;
    margin-bottom: 40px !important;
}

.description p {
    color: rgb(51, 51, 51);
    margin: 30px;
}

select.form-control, textarea.form-control, input.form-control {
    height: 46px;
}