.responsiveTableUserTable {
    height: 600px !important;
    display: block;
    width: 100%;
    /* overflow-x: auto; */
    overflow-y: auto !important;
    border-collapse: collapse;
    position: relative;
}



.UserTableModel {
    border: 1px solid #e5e5e5 !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !important;
    background-color: rgba(0, 0, 0, 0.5);
}

/* .EditButton {
    height: 30px !important;
} */