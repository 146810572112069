.statistics {
    margin-top: 100px;
}

.statisticElements {
    padding-top: 60px;
    padding-bottom: 20px;
}

.elements {
    padding-bottom: 20px;
}


.tableFixHead { overflow-y: auto;
    height: 450px;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
    background: white;
    border: 1px solid #dee2e6;
}

.raucList {
    /* overflow: scroll;
    overflow-y: scroll !important; */
    white-space: nowrap;
    width: 100%;
    height:450px !important;
}

.p{
    margin-bottom: 5px;
}