
.responsiveTable {
    display: block;
    position: relative;
    table-layout: fixed !important;
    width: 100% !important;
    height:65vh !important;

    text-align:center !important;
    margin-left:auto !important;
    margin-right:auto !important;

    overflow: scroll;
    overflow-y: scroll !important;
    white-space: nowrap;

    border-collapse: collapse;
    border-style: 1;
    border-width: 4px;
    border-color: lightgray;

    min-height: 250px !important;
    overflow-x: auto;
}

.table-filter-icon.selected{
    border-top-color: #001b94;
    box-shadow: inset 0 4px #001b94;
}

.container{
    max-width : 1700px !important;
}