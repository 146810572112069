.AppContainer {
  width: 100%;
}

.navbar-brand {
  font-weight: 600;
  font-size: 18px;
}

.navbar {
  background-color: #114c92;
  padding: .2rem 1rem;
}

.navbar .navbar-brand>a {
  color: white;
}

 div.mr-auto.navbar-nav>a {
  color:   white;
  ;
  font-size: 16px;
  padding: 8px;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
  font-size: 16px;
  padding: 8px;
}

.link {
  color: lightgray;
  text-decoration: none;
  background-color: transparent;
  font-size: 16px;
  padding: 8px;
}

a.link:hover {
  text-decoration: underline;
  color: white;
}

.nav-link:hover {
  /* text-decoration: underline; */
  color: lightgray;
}

.navbar-light .navbar-nav .nav-link:hover {
  text-decoration: underline;
  color: lightgray;
}

h1 {
  text-align: left;
  font-weight: 400;
  padding-top: 15px;
  padding-bottom: 10px;
}

.dev-attributes-heading{
  color: grey;
  font-size: small;

}

.dev-attributes-heading-annotations{
  color: lightgrey;
  font-size: small;
  padding-right: 0px;
  text-align: right;
}

.dev-details-annotations{
  color: lightgrey;
  padding: 20px;
  padding-right: 0px;
  text-align: right;
}

.dev-details-devicename{
  font-size: 1.4rem;
  font-weight: 600;
  color: #007bff;
  padding: 20px;
  padding-left: 0px;
  padding-bottom: 10px;
  text-align: left;
  
}

.dev-details-device-name-secondary{
  font-size: 1.0rem;
  font-weight: 400;
  color: #9caab9;
  padding: 20px;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 25px;
  text-align: left;
  float: left;
}

.customer-remark-box{
  background-color: #ddd;
  overflow-y: scroll;
  height: 40%;
  padding: 15px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.admin-remark-box{
  background-color: #ccd;
  overflow-y: scroll;
  height: 40%;
  padding: 15px;
  margin-top: 40px;
  margin-bottom: 20px;
}


.dev-shadow-state{
  background-color: #ccd;
  color: #00b;
  overflow-y: scroll;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50vh;
}

.dev-json-beautify{
  background-color: #ccd;
  color: #00b;
  overflow-y: scroll;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
}


.dev-details{
  margin-top: 24px;
  margin-bottom: 45px;
}


.dev-tabs{
  margin-top: 24px;
  margin-bottom: 24px;
}

.dev-attributes-row{
  padding-top: 5px;
  padding-bottom: 5px;
}

p {
  text-align: left;
}

h3 {
  padding-left: 0px;
  padding-top: 25px;
  padding-bottom: 10px;
}

h4 {
  padding-left: 0;
  margin-top: 25px;
}

h1 {
  padding-left: 0;
  padding-top: 30px;
  padding-bottom: 10px;
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;

}


.table td{
  font-size: small
}

.logo {
  /* width: 150px; */
  margin-right: 10px;
  max-height: 40px;
}

.actionbuttons{
  margin-top: 5px;
}

.numberCircle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  padding: 8px;

  background: blue;
  border: 2px solid #666;
  text-align: center;
}

.SessionTimeoutButton {
    font-size: 16px !important;
    height: 40px !important;
}