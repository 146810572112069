.support {
    padding: 10px 0;
    margin-bottom: 100px;
}

.supportText {
    font-size: 20px;
    padding-top: 50px;
    padding-bottom: 40px;
}

.supportIcon {
    font-size: 18px;
}

.supportButton {
    text-align: left;
    height: 70px;
    width: 400px;
    font-size: 20px;
}

.modal-backdrop {
    display: none;
}

.contact {
    font-size: 20px;
    margin: 50px;
}

.supportURL {
    text-align: center !important;
    color: aliceblue;
}