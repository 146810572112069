.responsiveTableHawkbit {
    min-height: 250px !important;
    max-height: 80vh;
    overflow-y: scroll;
}

.tagListTable {
    min-height: 250px !important;
    max-height: 60vh;
    overflow-y: scroll;
    border-color: lightgray;
    border-style: 1;
    border-width: 4px;
    border-collapse: collapse;
}