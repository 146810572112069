.Administration {
    padding: 10px 0;
    padding-bottom: 60px !important;
}

.adminText {
    font-size: 40px;
    padding-top: 50px;
    padding-bottom: 40px;
}

.featureList{
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.supportIcon {
    font-size: 30px;
}

.supportButton {
    text-align: center;
}

.supportText {
    font-size: 20px;
    padding-top: 50px;
    padding-bottom: 40px;
    color: aliceblue;
}

.supportURL {
    text-align: center !important;
    color: aliceblue;
}

.control {
    padding-top: 0px;
}

.form-control-static {
    min-height: 34px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0;
}

.Card-default > .Card-heading {
    background-color: #114c92;
    border-color: #ddd;
    color: #eee;
    font-size: 16px;
}

.control-Form.Label {
    font-size: 16px;
    font-weight: 501;
    margin-bottom: 8px;
}

.row {
    display: block;
}

.tableTop {
    padding-top: 24px;
}

.container {
    padding: 0 24px 0 24px;
}

.fullWidth {
    width: 100%;
}