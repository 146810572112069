.tableFixHead {
    overflow-y: auto !important;
    max-height: 500px !important;
}

.scrolledList {
    overflow-y: auto;
    height: 360px;
}

.listItem {
    background-color: rgb(222, 226, 226);
}