.footer {
    background-color: #114c92;
    color: white;
}

.footer a {
    color: white;
}

#Footer {
    clear: both;
    border: 1px groove #aaaaaa;
    background: blue;
    color: White;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    line-height: normal;
    margin: 0;
    position: fixed;
    bottom: 0px;
    width: 100%;
}