.control {
    padding-top: 0px;
}

.form-control-static {
    min-height: 34px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0;
}

.Card-default > .Card-heading {
    background-color: #114c92;
    border-color: #ddd;
    color: #eee;
    font-size: 16px;
}

h4 {
    font-size: 20px;
    color: #114c92;
    padding: 16px 0 16px 0px;
}

.container {
    padding: 0 24px 0 24px;
}

.h5 {

    font-size: 18px;
    color: #114c92;

}

.rowWidth {
    display: flex !important;
}

.row {
    display: flex !important;
}

.fullWidth {
    width: 100%;
}

.listItemHeading {
    color: #114c92;
}

.firmware-activeslot{
    background-color: #6fc28b;
    padding-top: 5px;
    padding-bottom: 5px;
}

.firmware-inactiveslot{
    background-color: #c0c1c2;
    white-space: pre-wrap;
    padding-top: 5px;
    padding-bottom: 5px;
}

.firmware-label{
    background-color: rgb(247, 247, 247);
    white-space: pre-wrap;
    padding-top: 5px;
    padding-bottom: 5px;
}

.row-no-padding{
    padding-top: 0px;
    padding-bottom: 0px;
}

.device-body {
    margin-top: -30px !important;
    margin-bottom: 100px !important;
}
