.tableFixHeadUploadFile {
    overflow-y: auto !important;
    max-height: 700px !important;
}

.tableFixHeadUploadFile thead {
    position: sticky !important;
    border: 1px solid #dee2e6 !important;
}

.tableFixHeadUploadFile thead tr th {
    background-color: rgb(235, 236, 236);
}

.scrolledList {
    overflow-y: auto;
    height: 400px;
}

.listItem {
    background-color: rgb(222, 226, 226);
}

.list-group-item {
    position:static;
}

.loadMoreJobsButton {
    display:flex;
    justify-content:center;
    align-items: center;
}

.table-fixed tbody {
    overflow-y: auto;
}