@media all and (min-width: 480px) {
    .ChangePassword {
        padding: 10px 0;
    }
    .ChangePassword form {
        margin: 0 auto;
        max-width: 550px;
    }
}

.ChangePassword form span.help-block {
    font-size: 16px;
    padding-bottom: 10px;
    color: #999;
}


.description {
    padding: 80px 0;
    text-align: center;
}

.description h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #114c92;
    font-size: 32px;
}

.description p {
    color: rgb(51, 51, 51);
    margin: 30px;
}

.pwAlert {
    text-align: left;
}

.SpecialCharacters{
    background-color: rgb(243, 242, 242) !important;
}

.AlertText {
    margin: 0px 0px 0px !important;
}


.ChangePasswordHomeButton {
    width: 100%;
}

.ChangedPasswordForm {
    margin-top: 100px !important;
}