.saveButton {
    width: 220px;
    height: 42px;
    margin-left: 15px;
    margin-bottom: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.hawkbitFeature {
    margin-top: 24px;
}

.rowWidth {
    display: flex !important;
    margin-bottom: 20px;
}

.row {
    display: flex !important;
}

.topCard {
    margin-left: 24px;
    margin-right: 24px;
}